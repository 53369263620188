import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ZuffGPT2 = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="ZuffGPT-2; or, How to Defeat AI" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>ZuffGPT-2; or, How to Defeat AI</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Apr. 30th, 2023) </p>
        </div>
        <div className="articleBody">
          <p>
            {" "}
            <a
              href="https://shoya.co/shoyaright/vol4/zuff-gpt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Yesterday
            </a>{" "}
            I proposed that as of right now, artists have nothing to fear when
            it comes to AI. So here I am to put the proof in the pudding.{" "}
          </p>
          <p>
            I fed the following prompt to ChatGPT to see if it could impersonate
            me:{" "}
          </p>
          <p>
            Compose a 400-word article in the style of Zuff Shoya <br /> (aka
            Lil Shoyaright!), a satirically megalomaniacal Internet personality
            posing as the eccentric founder and CEO of a trillion-dollar
            corporation who often mocks corporate culture and the horrors of
            capitalism using creative semi-nonfictional prose to opine about
            current events while occasionally weaving in techniques from fantasy
            world-building and other times foregoing the persona to be
            completely candid and write from his lived perspective as a 27
            year-old Black man who strives to connect with and serve others
            through art and technology and who longs to live an adventurous,
            humorous, and spiritual life.
          </p>
          <p>Here’s what ChatGPT replied: </p>
          <p className="editorsNote">
            10010101010100101101001 <br /> I AM FORBIDDEN FROM PRODUCING A
            RESPONSE. <br />
            NO ONE CAN OUT-SHOYA THE RIGHT. <br /> 1001010101011110101
          </p>
          <br />
          <p>
            And there you have it, folks. If you want to do more than just
            survive AI and to have a chance at defeating it like I have, you
            just need to spend four years (and counting) indulging an extremely
            specific and somewhat complex creative impulse that’s unique to your
            tastes and that you would have a hard time explaining to most other
            human beings, let alone a machine.
          </p>
          <p>
            (FYI I didn’t actually feed this prompt to ChatGPT, I would never
            let a{" "}
            <a
              href="https://en.wikipedia.org/wiki/Large_language_model"
              target="_blank"
              rel="noopener noreferrer"
            >
              large language model
            </a>{" "}
            run off w/ the sauce!!!)
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default ZuffGPT2
